import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btnCta"];

  clearForm() {
    this.element.reset();
  }

  saveNote(e) {
    // Uncomment to test markup, breaks none markup
    // this.btnCtaTarget.classList.add('is-saved');
    document.querySelector('.tm-order.is-selected').classList.add('is-saved');
    document.querySelector('.tm-order.is-selected').classList.remove('is-not-saved');
  }

  unsaveNote(e) {
    document.querySelector('.tm-order.is-selected').classList.remove('is-saved');
    document.querySelector('.tm-order.is-selected').classList.add('is-not-saved');
  }
}
